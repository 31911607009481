<style lang="scss">
.calenderad {
  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }

  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }

    .bild-box {
      width: 100%;

      background: #333;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .plus-size {
    padding-top: 0 !important;
    height: 2px;
    padding-bottom: 0 !important;
    @media (max-width: 768px) {
      height: 8px;
    }
    strong {
      position: relative;
      top: -14px;
      left: 25px;
      font-size: 160%;
      display: inline-block;
      @media (max-width: 768px) {
        top: -11px;
      }
    }
  }
}
</style>

<template>
  <div class="content calenderad container">
    <h1>Calendar Ad</h1>
    <p class="intro-txt">
      <strong>Ihr Event-Termin steht fest und keiner soll ihn vergessen?</strong><br />
      Über die Werbemöglichkeit Calendar Ad wird Ihre Veranstaltung auf <a href="https://www.kleinezeitung.at" target="_blank">www.kleinezeitung.at</a> ausgespielt. Unsere User haben die Möglichkeit, sich den Termin direkt in Ihren Kalendern einzutragen.
    </p>

    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6">
          <img src="@/assets/img/img-content/digital/bsp-calender-ad.png" alt="Bsp. Calendar Ad" class="img-fluid img-center" />
        </div>
        <div class="col-lg-6">
          <h2>Leistung &amp; Tarif</h2>

          <div class="tabelle-box">
            <div class="row head-row ">
              <div class="col-lg-8 text-align-left hide-small"></div>
              <div class="col-lg-4 text-align-right hide-small">Tarif</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-6 col-8 text-align-left ">Produktion</div>
              <div class="col-lg-6 col-4 text-align-right">158,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-6 col-8 text-align-left ">Tarif</div>
              <div class="col-lg-6 col-4 text-align-right">19,50 TKP</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center align-self-center bg-blue-light highlight-box margin-t-s margin-b-m">
      <div class="col-sm-6 d-flex align-self-center">
        <p>Buchbar über das Kleine Zeitung Display Tool</p>
      </div>
      <div class="col-sm-6 d-flex align-self-center justify-content-end">
        <div class="btn-std btn-bg-blue-dark">
          <a href="https://kleinezeitung.at/programmatic" target="_blank">Jetzt buchen!</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
});
</script>
